<template>
    <div>
        <section id="home" class="home-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 home-container">
                        <div class="headline">
                            <p>Welcome to the CreditorHub</p>
                            <h1>Register Your Claim, Cast Your Vote</h1>
                        </div>
                        <a href="/register" class="btn btn-primary btn-lg">Claim / Vote</a>
                        <br /><br /><br /><br /><br />
                    </div>
                </div>
            </div>
        </section>
        <div id="wrapper">
            <!-- Start intro -->
            <div id="intro" class="dark-section">
                <div class="container">
                    <div class="row text-center">
                        <div class="col-lg-10 offset-md-1">
                            <h4 class="headtitle">
                                <strong>creditorHub</strong> is a platform for managing creditor procedures under the new Insolvency Rules via an online creditor
                                portal. Host Virtual Meetings, Share Reports and Manage the Decision Process including Creditor Voting and Claims.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>

            <features></features>

            <introduction></introduction>

            <contact></contact>

            <div class="clearfix"></div>

            <custom-footer></custom-footer>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

import Contact from './contact.vue';
import Introduction from './introduction.vue';
import Features from './features.vue';
import Footer from './footer.vue';

export default Vue.extend({
    components: {
        Contact,
        Introduction,
        Features,
        'custom-footer': Footer
    }
});
</script>
